<template>
    <v-container fluid class="pa-0">
        <v-row class="justify-start">
            <v-col cols="12">

                <v-card borderless>
                    <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                        <div v-if="selectedItem" class="d-flex align-center">
                            <v-avatar class="mr-3">
                                <v-btn @click="() => (selectedItem = null)" fab>
                                    <v-icon>$vuetify.icons.arrowLeft</v-icon>
                                </v-btn>
                            </v-avatar>
                            <span v-html="selectedItem.title" />
                        </div>
                        <!--<div v-else>
                            Einstellungen
                        </div>-->
                    </v-card-title>
                    <v-card-text class="text-body-1 text--primary">
                        <v-container fluid class="px-0 pb-0">
                            <v-row no-gutters>
                                <v-col v-show="!selectedItem" cols="auto">
                                    <v-list class="py-0">
                                        <v-list-item v-for="item in pitems" :key="item.title" class="px-0"
                                                     @click="() => { if (item.action) item.action(); else selectedItem = item; }">

                                            <v-list-item-icon>
                                                <v-icon v-text="item.icon" large />
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title v-html="item.title" class="text-h6 font-weight-semibold text-wrap" />
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-btn v-if="item.component" icon>
                                                    <v-icon>$vuetify.icons.playArrow</v-icon>
                                                </v-btn>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                                <v-col v-if="selectedItem">
                                    <component :is="selectedItem.component"
                                               buttonLabel="Wechseln" @switchDone="switchIkDone" />

                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>

    
    import Stammdaten from '@/components/Stammdaten.vue'
    import PasswortAenderung from '@/components/PasswortAenderung.vue'
    //import KontoLoeschen from '@/components/KontoLoeschen.vue'
    import AuthenticatorAnzeigen from '@/components/AuthenticatorAnzeigen.vue'
    import Modulauswahl from '@/components/Modulauswahl.vue'
    //import SwitchIk from '@/components/SwitchIK.vue'
    //import Benutzerverwaltung from '@/components/Benutzerverwaltung.vue'
    import eventBus from '@/main'

    export default {
        name: 'PersoenlicheDaten',

        components: {
            Stammdaten,
            PasswortAenderung,
            //KontoLoeschen,
            AuthenticatorAnzeigen,
            Modulauswahl
            //SwitchIk,
            //Benutzerverwaltung
        },

        data: () => ({
            showDialog: false,
            showDialogPW: false,
            selectedItem: null,
        }),

        computed: {
            pitems() {
 
                let rst = [];
                let components = [
                    { title: 'Stammdaten und Email-Adresse', icon: '$vuetify.icons.database', component: 'stammdaten', needsRight: 4 },
                    { title: 'Passwort &auml;ndern', icon: '$vuetify.icons.keyChange', component: 'passwort-aenderung', needsRight: 0 },
                    { title: 'Authenticator erg&auml;nzen', icon: '$vuetify.icons.twoFactorAuthentication', component: 'authenticator-anzeigen', needsRight: 0 },
                    { title: 'Modulauswahl', icon: 'mdi-order-bool-ascending-variant', component: 'modulauswahl', needsRight: 4 },
                    //{ title: 'Benutzerverwaltung', icon: 'mdi-account', component: 'benutzerverwaltung', needsRight: 5 },
                ];

                const currentUser = this.$store.state.currentUser;
                if (currentUser) {
                    /*
                    if (!currentUser.gbDatum || new Date(currentUser.gbDatum) >= new Date(9999, 12, 31))
                        components.push({ title: 'Konto l&ouml;schen', icon: '$vuetify.icons.removeAccount', component: 'konto-loeschen', needsRight: 4 });

                    if (currentUser.gbDatum && new Date(currentUser.gbDatum) > new Date())
                        components.push({ title: 'K&uuml;ndigung stornieren', icon: '$vuetify.icons.undo', action: this.undoUnregister});

                    if (currentUser.canSwitchIK)
                        components.push({ title: 'Leistungserbringer wechseln', icon: '$vuetify.icons.accountSwitch', component: 'switch-ik' });
*/
                    rst = components.filter(c => {
                        return !c.needsRight
                            || currentUser.rechte.filter(r => r.recht === c.needsRight).length > 0;
                    });
                }

                return rst;
            },
        },

        activated() {
            this.selectedItem = null;
        },

        methods: {
            switchIkDone() {
                this.$router.push('/');
            },

        },
    }
</script>