<template>
    <v-container fluid class="pa-0">
        <v-row v-if="$store.state.currentUser">
            <v-col cols="12" sm="12" md="10" lg="8">

                <ul class="pl-8">
                    <li class="mb-4">
                        <v-row>
                            <v-col cols="12" sm="10" md="8">
                                <p>
                                    Zum Hinzufügen eines weiteren Gerätes geben Sie bitte ihr Passwort 
                                    sowie einen Sicherheitscode ihres aktuellen Authenticators ein:
                                </p>
                            </v-col>
                        </v-row>
                        <v-form>

                            <!-- Password forms should have (optionally hidden) username fields for accessibility -->
                            <v-text-field label="Benutzername" :value="$store.state.currentUser.benutzer"
                                          autocomplete="username" v-show="false" readonly />

                            <v-row class="align-center">
                                <v-col cols="12" sm="auto">
                                    <v-text-field label="Passwort" v-model="password"
                                                  autocomplete="current-password"
                                                  :type="showPasswort ? 'text' : 'password'"
                                                  :rules="[v => !!v || 'Bitte geben Sie das Passwort ein']"
                                                  :append-icon="showPasswort ? 'mdi-eye-off' : 'mdi-eye'"
                                                  @click:append="() => (showPasswort = !showPasswort)"
                                                  :readonly="isReadonly" required />

                                </v-col>
                                <v-col cols="12" sm="auto">
                                    <verify-otp @verifyOtpDone="(rst) => { this.jwt = rst; }" show-label />
                                </v-col>
                                <v-col cols="12" sm="auto">
                                    <v-btn color="primary" type="submit" :disabled="!password || !jwt || isReadonly"
                                           @click.stop.prevent="showAuthenticator" class="mb-2">

                                        Senden
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>

                        <v-alert border="left" type="error" v-if="errorMessage" class="mt-2">
                            {{ errorMessage }}
                        </v-alert>
                    </li>
                    <li v-show="secret2FA" class="mb-4">
                        <p>
                            Öffnen Sie nun ihre Authentifizierungs-App.
                        </p>
                    </li>
                    <li v-show="secret2FA">
                        <p>
                            Fügen Sie in der App ein Konto hinzu und scannen Sie den unten stehenden QR-Code.
                        </p>
                        <v-row class="mb-3">
                            <v-col cols="12" sm="auto" class="text-center">
                                <qrcode-vue :value="otpauthLink" size="200" level="H" />
                            </v-col>
                            <v-col>
                                <p>
                                    Sollten Sie den QR-Code nicht scannen können, dann geben Sie
                                    in der App bitte folgenden Schlüssel ein:
                                </p>
                                <code class="d-inline-flex font-weight-bold align-center text-left pa-0">
                                    <span class="ml-2 mr-1 py-1">
                                        {{ secret2FA && secret2FA.replace(/(.{4})/g, '$1 ').trim() }}
                                    </span>
                                    <v-btn color="black" type="button"
                                           @click.stop.prevent="shareSecret2FA"
                                           icon tile text>

                                        <v-icon>mdi-content-copy</v-icon>
                                    </v-btn>
                                    <a id="otpauthLinkOpener" :href="otpauthLink" class="d-none" />
                                </code>
                                <p v-show="copyClipboardSuccess" class="text-body-2 red--text">
                                    Schlüssel in Zwischenablage kopiert
                                </p>
                            </v-col>
                        </v-row>
                    </li>
                </ul>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import QrcodeVue from 'qrcode.vue';
    import VerifyOtp from '@/components/VerifyOtp.vue';

    const initialData = function () {
        return {
            secret2FA: null,
            errorMessage: null,
            password: null,
            showPasswort: false,
            isReadonly: false,
            copyClipboardSuccess: false,
            otpauthSchemeHandlerDetected: false,
            jwt: null,
        };
    };

    export default {

        name: 'AuthenticatorAnzeigen',

        components: {
            QrcodeVue,
            VerifyOtp,
        },

        data: function () {
            return initialData();
        },

        computed: {
            otpauthLink: function () {

                let label = this.$store.state.currentUser.leistungserbringer;
                let secret = this.secret2FA;
                let issuer = 'Service-Portal%20f%C3%BCr%20Apotheken';

                //z.B. Service-Portal für Apotheken (IK 123456789)
                return `otpauth://totp/IK%20${label}?secret=${secret}&issuer=${issuer}`;
            },
        },

        methods: {
            showAuthenticator() {
                this.$http
                    .post('auth/showauthenticator', {
                        Jwt: this.jwt,
                        Password: this.password,
                    })
                    .then(rsp => {
                        this.errorMessage = null;
                        this.secret2FA = rsp?.data;
                        this.isReadonly = true;
                    })
                    .catch(err => {
                        this.errorMessage = err?.response?.data?.message;
                    });
            },

            shareSecret2FA() {

                let timeout = 1000;
                let ctx = this;

                let copyToClipboard = function () {
                    navigator.clipboard.writeText(ctx.secret2FA);
                    ctx.copyClipboardSuccess = true;
                };

                let onBlur = function () {
                    ctx.otpauthSchemeHandlerDetected = true;
                };

                let onVisibilityChange = function (e) {
                    if (e.target.visibilityState === 'hidden') {
                        ctx.otpauthSchemeHandlerDetected = true;
                    }
                };

                if (!ctx.otpauthSchemeHandlerDetected) {

                    window.addEventListener('blur', onBlur);
                    document.addEventListener('visibilitychange', onVisibilityChange);

                    setTimeout(function () {

                        if (!ctx.otpauthSchemeHandlerDetected) {
                            copyToClipboard();
                        }

                        window.removeEventListener('blur', onBlur);
                        document.removeEventListener('visibilitychange', onVisibilityChange);

                    }, timeout);
                }

                ctx.copyClipboardSuccess = false;
                document.getElementById('otpauthLinkOpener').click();
            },
        },

        mounted() {
            Object.assign(this.$data, initialData());
        },
    }
</script>
